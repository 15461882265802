@import '../../styles/vars.less';

.Login {
  position: relative;
  height: 100vh;
  background: linear-gradient(241deg, #00b781 0%, #00b781 100%);

  .logo {
    position: absolute;
    top: 4px;
    left: 44px;
    width: 200px;
    height: 100px;
    background-image: url('../../assets/images/image.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .container {
    position: absolute;
    width: 100%;

    .pic {
      position: absolute;
      top:250px;
      left: 0;
      width: 50%;
      min-width: 20%;
      height: 520px;
      background: url('../../assets/images/bg2.png') no-repeat center bottom;
    }

    .form {
      position: absolute;
      right: 268px;
      @media (max-width: 1500px) {
        right: 0;
      }
      top: 180px;
      border-radius: 12px;
      width: 520px;
      min-width: 200px;
      padding: 40px;
      background-color: #fff;

      .inner {
        .formIcon {
          padding: 0 0 20px 0;
          color: #000;
          font-size: 16px;
          font-weight: bold;
          border-bottom: 1px solid #c5cad5;
        }

        .ant-btn{
          background-color: @primary;
        }

        .ant-input-affix-wrapper {
          padding: 0;
          border: none;

          &:focus,
          &-focused {
            box-shadow: none;
          }

          .ant-input-suffix {
            position: absolute;
            right: 24px;
            bottom: 16px;
            svg {
              color: #c6cad4;
            }
          }
        }

        .ant-input {
          padding: 14px 14px 14px;
          border: 1px solid #c5cad5;
          border-radius: 12px;
          // box-shadow: 0 0 0 1000px #fff inset;
          box-shadow: 0 0 0 1000px #f7f8fb inset;

          &:focus {
            border: 2px solid @primary;
            box-shadow: 0 0 0 1000px #edf7f3 inset,
              0px 0px 6px 0px rgba(@primary, 0.62) !important;
          }
        }


        .userName {
          padding-top: 20px;
        }


        .captcha {
          position: relative;

          .ant-form-item {
            margin: 0;
          }

          img {
            position: absolute;
            right: 0;
            bottom: 7px;
            z-index: 11;
            cursor: pointer;
          }
        }

        .submit {
          display: block;
          margin-top: 20px;
          width: 100%;
          height: 44px;
          font-size: 16px;
          border-radius: 48px;
          box-shadow: 0px 4px 4px 0px rgba(@primary, 0.42);
        }

        .icp {
          text-align: center;

          //  space {
          p {
            font-size: 12px;
            color: #b0b4bc;

            span {
              color: blue !important;
            }
          }
        }

        .pass_icp {
          &:extend(.icp);
          font-size: 12px;
          color: #b0b4bc;
          text-align: left !important;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {

    .container {
      display: flex;
      flex-direction: column;
    }
  
    .pic, 
    .form {
      width: 100%;
    }
  
    .pic {
      margin-bottom: 20px;
    }
  
  }

}

.reset-page {
  background-color: #fff;
  // margin-bottom: 5px;
  padding-bottom: 20px;
  margin-right: 5px;


  .detail-page {
    padding-left: 40px;
    padding-right: 40px;

    section {
      .title {
        font-size: 20px;
        font-family: 黑体;
        font-weight: bold;
        margin-top: 2%;
        // margin-bottom: 10%;

        .outlined-style {
          font-size: 20px;
          margin-right: 20px;
        }
      }

      .container {
        margin-top: 10%;
        display: flex;
        .img-style {
          display: flex;
          // flex-direction: column;
          // margin-top: 60px;
          flex: 30%;
          height: 204px;
          justify-content: center;
          align-content: center;
          background: url('../../assets/images/image1.png') no-repeat center bottom;
          // align-items: center;
          // .icp {
          // //  transform: translateX(83px);
          // text-align: left;
          // }
        }

        .form-style {
          flex: 70%;
          .limit-style {
            max-width: 80%;
          }

          .foot {
            text-align: center;
          }
        }

      }
    }
  }
}
@primary-color: #03b882;@text-color: #111a34;